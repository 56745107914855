import axios from "axios";
import { getToken, getApiKey, getUserInfo } from "./auth";

const api = axios.create({
  // baseURL: "https://dev-api-hom.agroreceita.com.br/v1"   //rota de desenvolvimento
  baseURL: "https://api.agroreceita.com.br/v1"    //rota de produção

});

api.interceptors.request.use(async config => {
  // config.headers.apiKey = 'corporativa@2021';
  const token = getToken();
  const apiKey = getApiKey();
  const userInfo = getUserInfo();


  if (apiKey === undefined) {
    config.headers.apiKey = '80c830989a1be5e43fa7';
  } else {
    if (apiKey) {
      // config.headers.apiKey = apiKey;
      config.headers.apiKey = '80c830989a1be5e43fa7';
    } else {
      config.headers.apiKey = '80c830989a1be5e43fa7';
    }
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (userInfo) {
    if (userInfo.id) {
      config.headers.userId = userInfo.id;
    }

    if (userInfo.agro_subsidiary_id){
      config.headers.subsidiaryId = userInfo.agro_subsidiary_id;
    }
  }



  return config;
});

export default api;
